import React, { Component } from "react";
import { Link } from "react-router-dom";
// import ReactExport from "react-export-excel";
import { StickyTable, Row, Cell } from "react-sticky-table";
import ReactTooltip from "react-tooltip";

import {
  getNumberDayofWeek,
  formatDate,
  isBetweenDate,
  getCurrrentUTCDate,
  addMinutesToDate,
  addDaysToDate,
  isBeforeDate,
  subtractDaysToDate
} from "../momentTools";
import "./CoverageTable.css";

// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const HourClass = { OpenOffice: "coverage-progress-bar-openoffice bg-teal", OnCall: "coverage-progress-bar-oncall bg-yellow", NoCoverage: "coverage-progress-bar-nocoverage bg-orange" }
let isWeekendOrHoliday = (date, holidays) => {
  if (getNumberDayofWeek(date) % 6 === 0 || holidays[date]) {
    return "coverage-table-cell-holiday";
  }
};

let getBacklogHours = (props, rotationDay) => {
  let { backlog, holidays } = props;
  let teams = props.teams[backlog.ID] || {};
  let startBacklog, endBacklog;

  if (!holidays[rotationDay.replace(/-/g,"")]) {
    startBacklog = backlog.WorkingHoursStart;
    endBacklog = backlog.WorkingHoursEnd;
    return { startBacklog, endBacklog };
  }

  Object.keys(teams).map((key) => {
    let teamLocation = teams[key].LocationID.split("-")[0];
    if (!holidays[rotationDay.replace(/-/g,"")][teamLocation]) {
      if (!startBacklog || startBacklog > teams[key].WorkingHoursStart) {
        startBacklog = teams[key].WorkingHoursStart;
      }
      if (!endBacklog || endBacklog < teams[key].WorkingHoursEnd) {
        endBacklog = teams[key].WorkingHoursEnd;
      }
    }
  });

  if (!startBacklog || !endBacklog) {
    startBacklog = backlog.WorkingHoursStart;
    endBacklog = backlog.WorkingHoursStart;
  }

  return { startBacklog, endBacklog };
};

let isOnCall = (props, iteratorDate, rotations) => {
  for (var index = 0; index < Object.keys(rotations).length; index++) {
    let rotationStart = rotations[Object.keys(rotations)[index]].StartTime;
    let rotationEnd = rotations[Object.keys(rotations)[index]].EndTime;
    

    if (isBetweenDate(iteratorDate + "+00:00", rotationStart, rotationEnd)) {
      return true;
    }
    
    
  }
  const previousRotationDate = subtractDaysToDate(iteratorDate,1,"YYYY-MM-DD");
  const backlogRotation = props.rotations[props.backlog.ID];
  if(!backlogRotation) return false;
  rotations = backlogRotation[previousRotationDate];
  for (var index = 0; rotations && index < Object.keys(rotations).length; index++) {
    let rotationStart = rotations[Object.keys(rotations)[index]].StartTime;
    let rotationEnd = rotations[Object.keys(rotations)[index]].EndTime;
    

    if (isBetweenDate(iteratorDate + "+00:00", rotationStart, rotationEnd)) {
      return true;
    }
    
    
  }
  const nextRotationDate = addDaysToDate(iteratorDate,1,"YYYY-MM-DD");
  rotations = backlogRotation[nextRotationDate];
  for (var index = 0; rotations && index < Object.keys(rotations).length; index++) {
    let rotationStart = rotations[Object.keys(rotations)[index]].StartTime;
    let rotationEnd = rotations[Object.keys(rotations)[index]].EndTime;
    

    if (isBetweenDate(iteratorDate + "+00:00", rotationStart, rotationEnd)) {
      return true;
    }
    
    
  }
  return false;
};

let isWeekend = (iteratorDate) => {
  return getNumberDayofWeek(iteratorDate) % 6 === 0;
};

let calculateCoverage = (props, rotationDay, rotations) => {//1.10.11-1 calculate coverage hours in a day 
  let { startBacklog, endBacklog } = getBacklogHours(props, rotationDay);
  let startBacklogDate = rotationDay + "T" + startBacklog;
  let startBacklogDateNext = addDaysToDate(
    startBacklogDate,
    1,
    "YYYY-MM-DDTHH:mm"
  );
  let endBacklogDate = rotationDay + "T" + endBacklog;

  if (isBeforeDate(endBacklogDate, startBacklogDate)) {
    endBacklogDate = addDaysToDate(endBacklogDate, 1, "YYYY-MM-DDTHH:mm");
  }

  let Coverage = {
    OpenOffice: 0,
    OnCall: 0,
    NoCoverage: 0,
    Hour: []
  };

  for (
    var iteratorDate = addMinutesToDate(
      rotationDay + "T00:00",
      5,
      "YYYY-MM-DDTHH:mm"
    ),hour = 0;
    isBeforeDate(iteratorDate, startBacklogDateNext) && hour < 24;
    iteratorDate = addMinutesToDate(iteratorDate, 60, "YYYY-MM-DDTHH:mm"),hour++
  ) { //1.10.11-2 Iterate over each hour in the rotation day
    if (
      !isWeekend(iteratorDate) &&
      isBetweenDate(iteratorDate, startBacklogDate, endBacklogDate)
    ) { //1.10.11-3 OpenOffice hour++ if current hour index falls between backlog open and close time
      Coverage.OpenOffice++; Coverage.Hour.push(HourClass.OpenOffice);
    } else if (isOnCall(props, iteratorDate, rotations)) {
      Coverage.OnCall++; Coverage.Hour.push(HourClass.OnCall);
    } //1.10.11-4 OnCall hour++ if current hour index falls between a scheduled rotation open and close time
    else {
      Coverage.NoCoverage++; Coverage.Hour.push(HourClass.NoCoverage);//1.10.11-4 NoCoverage hour++ if current hour index doesnt fall in team or rotation
    }

  }

  return Coverage;
};

let listRotations = (props, rotationDay, rotations, excelCell) => {/* 1.10.11-7 list rotations for the day */
  if (Object.keys(rotations).length === 0) {
    excelCell.push(`No On-Call rotations!`);
    return (
      <p>
        <i>No On-Call rotations!</i>
      </p>
    );
  } else {
    return Object.keys(rotations).map((userId) => {
      excelCell.push(
        `${rotations[userId].DisplayName} ${rotations[userId].LastName}`
      );
      excelCell.push(
        `${rotations[userId].PrimaryPhone || ""} ${rotations[userId].FallbackPhone || ""
        }`
      );
      excelCell.push(`${rotations[userId].Location}`);
      return (
        <p>
          {/*<b>
            {rotations[userId].DisplayName} {rotations[userId].LastName}{" "}
          </b>
          <br /> {rotations[userId].PrimaryPhone}
          {rotations[userId].FallbackPhone && (
            <>
              <br />
              {rotations[userId].FallbackPhone}
            </>
          )}
          <br />1.10.11-7 Dont show personal details */}
          {rotations[userId].Location}
        </p>
      );
    });
  }
};

let pushToArray = (array, value) => {
  array.push(value);
  return;
};

let renderCell = (props, rotationDay, rotations, excelRow) => {/*1.10.11 show rotation details for each backlog and date */

  let Coverage = calculateCoverage(props, rotationDay, rotations, excelRow);

  var excelCell = [];

  return (
    <div>
      <div className="coverage-progress-container">

        {/*1.10.11-5 Show the coverage breakdown linearly and visually in 24 hrs*/}
        <div className="coverage-progress-bar">
          {Coverage.Hour.map((hourClass) => <div data-tip
            data-for={hourClass}
            className={hourClass}
            style={{ width: parseInt((1 / 24) * 100) + "%" }}
          ></div>)}
        </div>
        <div className="coverage-progress-label">
          {parseInt(((Coverage.OpenOffice + Coverage.OnCall) / 24) * 100) + "%"} {/*1.10.11-5 Show the % coverage in 24 hrs*/}
          {pushToArray(
            excelCell,
            `Coverage: ${parseInt(((Coverage.OpenOffice + Coverage.OnCall) / 24) * 100) +
            "%"
            }`
          )}
          {/* 1.10.11-6 push values to excel data set  */}
        </div>
      </div>
      <div>
        {/* <p>OO: {Coverage.OpenOffice} OC: {Coverage.OnCall} NC: {Coverage.NoCoverage}</p> */}
        {listRotations(props, rotationDay, rotations, excelCell)}{/* 1.10.11-7 list rotations for the day */}
        {pushToArray(excelRow, excelCell.join(String.fromCharCode(10)))}
      </div>
    </div>
  );
};

const renderHour = (hour,date) => {
  const currentDateTime = getCurrrentUTCDate("YYYY-MM-DDTHH:mm");
  const rotationDateTime = formatDate(date,"YYYY-MM-DDTHH:mm")
  const startInterval = addMinutesToDate(rotationDateTime,60*hour,"YYYY-MM-DDTHH:mm")
  const endInterval = addMinutesToDate(rotationDateTime,60*(hour+1),"YYYY-MM-DDTHH:mm")
  return (isBetweenDate(currentDateTime,startInterval,endInterval) ? <span className="blinkingRed">!</span> : "|")

  
  
}


class CoverageTable extends Component {
  render() {/*1.10.3 show coverage table */
    let { dates, holidays, backlogs, teams, rotations, showLarge } = this.props;
    const hour24 = [...Array(24).keys()]
    let multiDataSet = [
      {
        columns: ["Backlog"],
        data: [],
      },
    ];

    /*1.10.8 populate columns for excel */

    dates.map((date) =>
      multiDataSet[0].columns.push(
        `${formatDate(date, "MM/DD")}${String.fromCharCode(
          10
        )}(${formatDate(date, "dddd")})`
      ));
    return (
      <React.Fragment>{/*1.10.4 excel download - REMOVED*/}
        

        <StickyTable id="coverage-table" className={showLarge ? "large" : ""}>{/*1.10.5 Use sticky table to fix first column */}
          <Row>
            <Cell className="coverage-table-header">Backlog</Cell>{/*1.10.6 Header consists of Backlog and next 7 days */}
            {dates.map((date) => {
              return (
                <Cell
                  key={date}
                  className={
                    "coverage-table-header " +
                    isWeekendOrHoliday(date, holidays)
                  }
                > {/*1.10.7 Change header color for holiday or weekend */}
                  {formatDate(date, "MM/DD")}
                  <br />
                  {formatDate(date, "dddd")}
                  <br />
                  <div className="coverage-progress-container">
                  <div className="coverage-progress-bar" >
                  {/*1.10.12 Show 24 hr hourly interval marking current time */}
                  {hour24.map((hour, key) => {
                    return (
                    <div key={key} className="coverage-progress-bar-hour  " style={{ width: parseInt((1 / 24) * 100) + "%" }}>
                      <div> {renderHour(hour,date)}</div>
                    </div>
                    )
                  }) }
                  
                  </div>
                  </div>
                 
                </Cell>
              );
            })}
          </Row>
          {Object.keys(backlogs).map((backlogId, key) => {
            let backlog = backlogs[backlogId];

            var excelRow = [`${backlog.Name} (${backlog.ID})`];
            multiDataSet[0].data.push(excelRow);
            {/*1.10.9 show backlog name in sticky column's rows */ }
            return (
              <Row key={backlog.ID}>
                <Cell className="coverage-table-body">
                  <b>{backlog.Name}</b>
                  <br />
                  {backlog.ID}
                  <br />
                  {/*1.10.10 Schedule button opens backlog */}
                </Cell>
                {dates.map((date) => {
                  let currentRotations =
                    rotations[backlog.ID] && rotations[backlog.ID][date]
                      ? rotations[backlog.ID][date]
                      : {};
                  {/*1.10.11 show rotation details for each backlog and date */ }

                  return (
                    <Cell
                      className="coverage-table-body"
                      key={backlog.ID + "_" + date}
                    >
                      {renderCell(
                        {
                          dates,
                          holidays,
                          backlog,
                          teams,
                          rotations,
                          showLarge,
                        },
                        date,
                        currentRotations,
                        excelRow
                      )}
                    </Cell>
                  );
                })}
              </Row>
            );
          })}
        </StickyTable>
      </React.Fragment>
    );
  }
}

export default CoverageTable;
