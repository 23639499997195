import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getBacklog,
  getHolidays,
  getPeopleInBacklog,
  getTeamsInBacklog,
} from "../../../api";
import { getCurrrentUTCDate } from "../../../momentTools";
import Calendar from "../../Rotation/Calendar";
import TimezoneSelector from "../../TimezoneSelector";
import AddUser from "./People/AddUser";
import PeopleInBacklog from "./PeopleInBacklog";
import Loader from "../../Loader";
import Teams from "./Teams";

class Backlog extends Component { // 1.9 The individual backlog view
  state = {
    backlogId: null,
    backlog: {},
    teams: {},
    users: {},
    timeZone: "Etc/UTC",
    isEditMode: false,
    holidays: {},
    showLoader: true,
  };

  componentDidMount() {
    let id = this.props.match.params.backlogId;
    this.setState({ showLoader: true });

    this.setState({ backlogId: id }, () => { // 1.9.1 backlog page onload - 
      getBacklog(this.state.backlogId).then((res) =>
        this.setState({ backlog: res[this.state.backlogId] }) // 1.9.1-1 get Backlog ID details object
      );
      getTeamsInBacklog(this.state.backlogId).then((res) => // 1.9.1-2 get all teams in the current backlog
        this.setState({ teams: res })
      );
      getPeopleInBacklog(this.state.backlogId).then((res) => // 1.9.1-3 get all people on the roster in backlog
        this.setState({ users: res })
      );
      getHolidays(getCurrrentUTCDate("YYYY-MM")).then((res) => {// 1.9.1-4 get holidays (why again ?? )
        this.setState({ holidays: res });
        this.setState({ showLoader: false });
      });
    });
  }

  handleToggle = () => {
    this.setState({ isEditMode: !this.state.isEditMode });// 1.9.2 Show the add engineer to roster dialog
  };

  onSuccessAdd = (message) => {
    getPeopleInBacklog(this.state.backlogId)
      .then((res) => this.setState({ users: res }))
      .then(
        toast.success("User was added!", { position: toast.POSITION.TOP_RIGHT })
      );
  };

  onSuccessDelete = (message) => {
    getPeopleInBacklog(this.state.backlogId)
      .then((res) => this.setState({ users: res }))
      .then(
        toast.error("User was deleted!", { position: toast.POSITION.TOP_RIGHT })
      );
  };

  canPreview = (property) => { // 1.9.5 provide Edit Schedule options to BacklogRep
    const { ActiveUser, Roles } = this.props;

    if (!ActiveUser.role) return false;

    switch (property) {
      case "editor":
        return Roles.BacklogRepresentative.includes(ActiveUser.role);
    }
  };

  render() {
    const { teams, users, backlog, timeZone, isEditMode } = this.state;

    return (
      <>
       {this.state.showLoader && (
          <Loader showLoader={this.state.showLoader} />
        )}

      {(Object.keys(this.state.users).length !== 0  && Object.keys(this.state.backlog).length !== 0) && !this.state.showLoader  && (
      <div className="Backlog col-sm-12 bottom-30">
        <div className="row pb-5">
          <div className="col-sm-12">
            <h2 className="text-left ">
              {backlog.Name} ({backlog.ID}) <br />{" "} 
            
            </h2>  
            <TimezoneSelector
              setTimezone={(value) => this.setState({ timeZone: value })}
              getTimezone={() => this.state.timeZone}
              ActiveUser={this.props.ActiveUser}
            />  {/* 1.9.3 Timezone setter  */}
            <ToastContainer autoClose={3000} />
          </div>
        </div>
        <div className="row pt-5">
          <div className="col-lg-4">
            <div className="row">
              <h5>
                <span>
                  <i className="material-icons">access_time</i>Regular Working
                  Hours
                </span>
              </h5>
            </div>
            <div className="row">
              <div className="col-sm-12">
                {Object.keys(teams).map((key, index) => (
                  <Teams
                    key={teams[key].ID}
                    timeZone={timeZone}
                    team={teams[teams[key].ID]}
                    holidays={this.state.holidays}
                  />
                ))} {/* 1.9.4 Show teams in backlogs */}
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="row">
              <h5>
                <span className="right-10">
                  <i className="material-icons">people</i>On-Call Rotation
                </span>
                {this.canPreview("editor") && (
                  <Link
                    className="btn btn-primary"
                    to={`/rotation/${backlog.ID}`}
                  >
                    Edit Schedule
                  </Link> 
                )}{/* 1.9.5-1 provide Edit Schedule options to BacklogRep */}
                {/* 1.9.5-2 Edit Schedule link  routes to /rotation/backlogid */}
              </h5>
            </div>
            <div className="row">
              {Object.keys(this.state.backlog).length !== 0 && (
                <Calendar
                  readOnly={true}
                  previewOnly={true}
                  rotationBacklog={this.state.backlog}
                  backlogUsers={this.state.users}
                  timeZone={timeZone}
                />
              )} 
            </div>
          </div>
        </div>
        <div className="row pt-5">
          <div className="col-sm-12">
            <div className="row">
              <h5>
                <span>
                  <i className="material-icons">people_outline</i>On-Call
                  Engineers In Backlog
                </span>
              </h5>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="row pt-2">
                  {Object.keys(users).map((key, index) => (
                    <PeopleInBacklog
                      key={users[key].ID}
                      user={users[users[key].ID]}
                      onSuccessDelete={this.onSuccessDelete}
                      ActiveUser={this.props.ActiveUser}
                      Roles={this.props.Roles}
                    />
                  ))} {/* 1.9.6 Show the engineers in the roster  */}

                  {this.canPreview("editor") && (
                    <div className="col-sm-4">
                      <div className="card add-user border-teal top-20">
                        <div className="card-body">
                          <h5 className="card-title">Add On-Call Engineer</h5>
                          <button
                            className="btn btn-success"
                            onClick={this.handleToggle}
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                  )}  {/* 1.9.7 Add OnCall engineer capability to only backlog reps */}

                  {isEditMode && this.canPreview("editor") && (
                    <AddUser
                      backlog={backlog}
                      handleToggle={this.handleToggle}
                      onSuccessAdd={this.onSuccessAdd}
                      onFailureAdd={this.onFailureAdd}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )}
    </>
    );
  }
}

export default Backlog;
