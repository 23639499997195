import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BacklogCardItem from "./BacklogCardItem";
import ContactButton from "./ContactButton/ContactButton";

const BacklogCard = (props) => { // 1.7 Backlog display
  const { timeZone, backlog, locations, ActiveUser, getUserName } = props;
  const [firstHelp, setFirstHelp] = useState(null);
  const [showContact, setShowContact] = useState(false);
  const backlogColor = 
    backlog.status === "OPEN OFFICE"
      ? "teal"
      : backlog.status === "ON-CALL"
      ? "yellow"
      : "orange";// 1.7.1 Calculate backlog color
  const toggleModal = () => {{/* 1.7.14 Modal dialog   */}
    setShowContact(!showContact);
  }; 

  //const [roostermanagername, setRoostermanager] = useState({});

  useEffect(() => {
    let nextTeamStartHour = backlog.nextTeams
      ? backlog.nextTeams[Object.keys(backlog.nextTeams)[0]].WorkingHoursStart
      : undefined;
    let nextRotationStartHour = backlog.nextRotations
      ? backlog.nextRotations[Object.keys(backlog.nextRotations)[0]]
          .StartTime
      : undefined;

    if (
      nextTeamStartHour &&
      nextRotationStartHour &&
      nextTeamStartHour < nextRotationStartHour
    ) {
      setFirstHelp(backlog.nextTeams); // 1.7.2 when start time of next team is earlier than start of next engineer rotations, set team as upcoming
    } else {
      setFirstHelp(backlog.nextRotations);// 1.7.2 when start time of next rotation is earlier than start of next team , set rotation as upcoming
    }

    if (nextTeamStartHour && !nextRotationStartHour) {
      setFirstHelp(backlog.nextTeams);//1.7.3 when there is no next rotation set next team as upcoming
    }

    if (!nextTeamStartHour && nextRotationStartHour) {
      setFirstHelp(backlog.nextRotations);//1.7.4 when there is no next team set next rotation as upcoming (??)
    }
  // console.log(backlog.RosterManager1Id )
    const roosterm1 =  backlog.RosterManager1Id 
    //console.log(roosterm1)
    //getUser(roosterm1).then( res => {
    //  setRoostermanager(res[roosterm1])
    //})
    
    
  });

  var canPreview = (property, props) => { // 1.7.5 show phone number only to Backlog Rep or Escalation Leadership 
    const { ActiveUser, Roles } = props;

    if (!ActiveUser.role) return false; 

    switch (property) {
      case "phone":
        return (
          Roles.BacklogRepresentative.includes(ActiveUser.role) ||
          Roles.EscalationLeadership.includes(ActiveUser.role)
        );
    }
  };

  return (
    <div className="col-lg-6 col-md-12 col-sm-12">
      <div className={"card pb-4 mb-5 border-" + backlogColor}>
        <div className="row">
          <div className="col-sm-12">
            <div className="p-3">
              <h4 className="text-left">
                {backlog.Name} <small>({backlog.ID})</small>{/*1.7.6 Show backlog name and (backlog id)*/}
              {' '}
              <Link
                  className="btn btn-danger px-3 top-10"
                  to={`/backlog/${backlog.ID}`}
                >{/*1.7.9 onclick of schedule navigate to route for opening specific backlog*/}
                  Schedule 
                </Link> 
              
              </h4>
              <span className="input-group-btn">
                <h5>
                  <span
                    className={
                      "ml-2 text-white badge backlog bg-" + backlogColor
                    }
                  >
                    {backlog.status}{/*1.7.8 show status ??*/}
                  </span>
                </h5>
              </span>
              <div className={`horizontal-line bg-${backlogColor}`}></div>
            </div>
          </div>
        </div>
        <div className="row pb-2">
          <div className="col-sm-4 text-left ml-3 pb-3">
            <div className="row h-100">
              <div className="col-sm-12 my-auto">
             <span className="font-bold "> Roster Manager(s):  </span>   <br/>
              <div>
                  <>
              { backlog.RosterManager1Id && Object.keys(backlog.RosterManager1Id).length !== 0 ? getUserName(backlog.RosterManager1Id) : "-"} <br/>
              </>
              <> 
              { backlog.RosterManager2Id && Object.keys(backlog.RosterManager2Id).length !== 0 ? getUserName(backlog.RosterManager2Id) : ""} <br/>
              </>
              <>
              { backlog.RosterManager3Id && Object.keys(backlog.RosterManager3Id).length !== 0 ? getUserName(backlog.RosterManager3Id) : ""} 
              </>
              </div>
            </div>
          </div>
          </div>
          <div className="col-sm-7">
            <div className="row">
              <div className="col-sm-12">
                {/*1.7.12 Show either active rotation if it exists or else active team if it exists */}
                {backlog.activeRotations && 
                  Object.keys(
                    backlog.activeRotations || {}
                  ).map((key, index) => (
                    <BacklogCardItem
                      key={backlog.activeRotations[key].ID}
                      item={backlog.activeRotations[key]}
                      timeZone={timeZone}
                      locations={locations}
                      activeUser={ActiveUser} 
                    />
                  ))}
                {backlog.teams &&
                  Object.keys(backlog.teams).map((key, index) => (
                    <BacklogCardItem
                      key={backlog.teams[key].ID}
                      item={backlog.teams[key]}
                      timeZone={timeZone}
                      locations={locations}
                      activeUser={ActiveUser}
                    />
                  ))}
                {backlog.status === "NO COVERAGE" && firstHelp && (
                  <h6 className="font-extra-bold text-orange bottom-20">
                    Upcoming Support
                  </h6>
                )}{/*1.7.13 When No Coverage show upcoming rotation or team (firstHelp)*/}
                {backlog.status === "NO COVERAGE" &&
                  firstHelp &&
                  Object.keys(firstHelp || {}).map((key, index) => (
                    <BacklogCardItem
                      key={firstHelp[key].ID}
                      item={firstHelp[key]}
                      timeZone={timeZone}
                      locations={locations}
                      activeUser={ActiveUser}
                    />
                  ))}

                {showContact && canPreview("phone", props) && (
                  <ContactButton
                    teams={backlog.teams}
                    rotations={backlog.activeRotations}
                    timeZone={timeZone}
                    toggleModal={toggleModal}
                  />

                )}
                {/* 1.7.14 Modal dialog   */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BacklogCard;
