import React, { Component } from "react";
import {
  getBacklogs,
  getPeopleInBacklog,
  postRotations,
  getTeamsInBacklog,
  getRotation,
} from "../api";
import {
  // addDaysToDate,
  // addHoursToDate,
  // addHoursToDateTime,
  // getTimeDiff,
  formatDate,
  // getLocalizedTimefromDate,
  getLocalizedTime,
  // getNumberDayofWeek,
  // subtractDaysToDate,
} from "../momentTools";
import MyModal from "./Modal";
import "./Rotation.css";
import Calendar from "./Rotation/Calendar";
import RotationList from "./Rotation/RotationList";
import TimezoneSelector from "./TimezoneSelector";
import { toast } from "react-toastify";
import Loader from "./Loader";
import { StickyTable, Row, Cell } from "react-sticky-table";

class Rotation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeZone: "Etc/UTC",
      localTime: null,
      backlogs: {},
      backlog: {},
      peopleInBacklog: undefined,
      user: undefined,
      selectedRotations: {},
      submittedRotationsRaw: [],
      removedRotations: {},
      submittedRotations: {},
      rotationClass: "PRIM",
      rotationChunk: "1/1",
      showLoader: false,
    };
  }

  componentDidMount() {
    let id = this.props.match.params.backlogId;
    getBacklogs().then((backlogs) => {
      //debugger;
      this.setState({ backlogs: backlogs });
      if (id) {
        this.handleChangeBacklog(id);
      }
    });

    let currentDate = formatDate(this.state.currentDate, "YYYY-MM");
    getRotation(id, currentDate).then((res) => {
      const modifiedRotationsWithUniqueID = Object.entries(res)
        .map(([key, obj]) => {
          return {
            [key]: Object.entries(obj).reduce((prev, curr) => {
              prev[[curr[1].ID]] = curr[1];
              return prev;
            }, {}),
          };
        })
        .reduce((prev, curr) => {
          Object.entries(curr).map(([key, obj]) => {
            prev[key] = obj;
          });
          return prev;
        }, {});
      this.setState({
        submittedRotations: modifiedRotationsWithUniqueID,
        submittedRotationsRaw: modifiedRotationsWithUniqueID,
      });
    });
    getTeamsInBacklog(id).then((res) => this.setState({ teams: res }));
  }

  handleChangeBacklog = (backlogId) => {
    this.setState({
      backlog: this.state.backlogs[backlogId],
      user: {},
      selectedRotations: {},
      removedRotations: {},
      peopleInBacklog: undefined,
    });
    getPeopleInBacklog(backlogId).then((res) => {
      this.setState({
        peopleInBacklog: res,
        user: res[Object.keys(res)[0]],
      });
    });
  };

  canPreview = (property) => {
    // 1.9.5 provide Edit Schedule options to BacklogRep
    const { ActiveUser, Roles } = this.props;

    if (!ActiveUser.role) return false;

    switch (property) {
      case "editor":
        return Roles.BacklogRepresentative.includes(ActiveUser.role);
    }
  };

  handleToggle = () => {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  };

  onSubmitRotation = () => {
    let postData = {
      backlog: this.state.backlog,
      selectedRotations: this.state.selectedRotations,
      removedRotations: this.state.removedRotations,
    };
    try {
      this.setState({ showLoader: true });
      postRotations(postData).then((res) => {
        this.setState({ modalBody: res.message });
        setTimeout(() => {
          this.setState({ showLoader: false });
        }, 500);
      });
    } catch (error) {
      this.setState({ showLoader: false });
      toast.dismiss();
      toast.error("Rotation submission has failed, please retry", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  onSelectedRotationsUpdate = (selectedRotations) => {
    this.setState({ selectedRotations: selectedRotations });
  };

  onRemovedRotationsUpdate = (removedRotations) => {
    this.setState({ removedRotations: removedRotations });
  };

  onChunkChange = (rotationChunk) => {
    this.setState({ rotationChunk });
  };

  onClassChange = (rotationClass) => {
    this.setState({ rotationClass });
  };

  onEngineerChange = (engineerId) => {
    // debugger;
    if (!this.state.peopleInBacklog[engineerId].PhonePrimary) {
      return;
    }
    this.setState({
      user: this.state.peopleInBacklog[engineerId],
      rotationChunk: "1/1",
      rotationClass: "PRIM",
    });
  };

  render() {
    //const {Roles} = this.props;
    const {
      backlog,
      backlogs,
      user,
      peopleInBacklog,
      timeZone,
      rotationClass,
      rotationChunk,
      teams,
      ActiveUser,
    } = this.state;

    let IndiaWeekdayOnCallStart = backlog.IndiaWeekdayOnCallHoursStart
      ? backlog.IndiaWeekdayOnCallHoursStart
      : backlog.WorkingHoursEnd;
    let IndiaWeekdayOnCallEnd = backlog.IndiaWeekdayOnCallHoursEnd
      ? backlog.IndiaWeekdayOnCallHoursEnd
      : backlog.WorkingHoursStart;

    let IndiaHolidayOnCallStart = backlog.IndiaHolidayOnCallHoursStart
      ? backlog.IndiaHolidayOnCallHoursStart
      : backlog.WorkingHoursStart;
    let IndiaHolidayOnCallEnd = backlog.IndiaHolidayOnCallHoursEnd
      ? backlog.IndiaHolidayOnCallHoursEnd
      : backlog.WorkingHoursStart;

    let EMEAOncallWorkdayStart = backlog.EMEAWeekdayOnCallHoursStart ? backlog.EMEAWeekdayOnCallHoursStart : "15:00";
    let EMEAOncallWorkdayEnd = backlog.EMEAWeekdayOnCallHoursEnd ? backlog.EMEAWeekdayOnCallHoursEnd : "20:00";
    let EMEAOncallHolidayStart = backlog.EMEAHolidayOnCallHoursStart ? backlog.EMEAHolidayOnCallHoursStart : "07:00";
    let EMEAOnCallHolidayEnd = backlog.EMEAHolidayOnCallHoursEnd ? backlog.EMEAHolidayOnCallHoursEnd : "20:00";

    return (
      <div className="Rotation">
        <div className="row pt-3">
          <div className="col-sm-6">
            <h1 className="section-header"> Create On-Call Rotation </h1>
          </div>
          <div className="col-sm-6">
            <TimezoneSelector
              setTimezone={(value) => this.setState({ timeZone: value })}
              getTimezone={() => this.state.timeZone}
              ActiveUser={this.props.ActiveUser}
            />
          </div>
        </div>

        <div className="row pt-3">
          <div className="col-sm-12">
            <div className="row my-3 justify-content-md-center">
              <div className="col-sm-12">
                <h4 className="text-left bottom-20">
                  <span className="text-teal border-teal rotation-numerator">1</span>
                  {backlog.Name}
                </h4>
              </div>

              {backlog.ID && (
                <>
                  <StickyTable>
                    <Row>
                      <Cell className="bg-light font-weight-bold">Category</Cell>
                      <Cell className="bg-light font-weight-bold border-right">Working Day</Cell>
                      <Cell className="bg-light font-weight-bold">Holiday</Cell>
                    </Row>

                    <Row>
                      <Cell className="border-left">Working Hours</Cell>
                      <Cell className="border-right">
                        {getLocalizedTime(backlog.WorkingHoursStart, timeZone)} -{" "}
                        {getLocalizedTime(backlog.WorkingHoursEnd, timeZone)}
                      </Cell>
                      <Cell className="border-right">-</Cell>
                    </Row>

                    <Row>
                      <Cell className="border-left">India OnCall Hours</Cell>
                      <Cell className="border-right">
                        {getLocalizedTime(IndiaWeekdayOnCallStart, timeZone)} -{" "}
                        {getLocalizedTime(IndiaWeekdayOnCallEnd, timeZone)}
                      </Cell>
                      <Cell className="border-right">
                        {getLocalizedTime(IndiaHolidayOnCallStart, timeZone)} -{" "}
                        {getLocalizedTime(IndiaHolidayOnCallEnd, timeZone)}
                      </Cell>
                    </Row>

                    <Row>
                      <Cell className="border-left">EMEA (PL and NL) OnCall Hours</Cell>
                      <Cell className="border-right">
                      {getLocalizedTime(EMEAOncallWorkdayStart, timeZone)} -{" "}
                      {getLocalizedTime(EMEAOncallWorkdayEnd, timeZone)}
                      </Cell>
                      <Cell className="border-right">
                      {getLocalizedTime(EMEAOncallHolidayStart, timeZone)} -{" "}
                      {getLocalizedTime(EMEAOnCallHolidayEnd, timeZone)}
                      </Cell>
                    </Row>

                    <Row>
                      <Cell className="border-left">US OnCall Hours </Cell>
                      <Cell className="border-right">
                        {getLocalizedTime(backlog.WorkingHoursEnd, timeZone)} -{" "}
                        {getLocalizedTime(backlog.WorkingHoursStart, timeZone)}
                      </Cell>
                      <Cell className="border-right">
                        {getLocalizedTime(backlog.WorkingHoursStart, timeZone)} -{" "}
                        {getLocalizedTime(backlog.WorkingHoursStart, timeZone)}
                      </Cell>
                    </Row>

                    <Row>
                      <Cell className="border-left">Owner </Cell>
                      <Cell className="border-right"> {backlog.Owner ? backlog.Owner : "none"}</Cell>
                      <Cell className="border-right">-</Cell>
                    </Row>

                    <Row>
                      <Cell className="border-left"> Feature </Cell>
                      <Cell className="border-right">{backlog.Feature}</Cell>
                      <Cell className="border-right">-</Cell>
                    </Row>
                  </StickyTable>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="row pt-3">
          <div className="col-sm-12">
            {peopleInBacklog && (
              <div className="row top-50 mb-5">
                <div className="col-lg-12">
                  <h4 className="text-left bottom-20">
                    <span className="text-teal border-teal rotation-numerator">
                      2
                    </span>
                    Choose Dates
                  </h4>
                  <Calendar
                    oncallTimes={{
                      indOnCallWorkdayStart: IndiaWeekdayOnCallStart,
                      indOnCallWorkdayEnd: IndiaWeekdayOnCallEnd,
                      indOnCallHolidayStart: IndiaHolidayOnCallStart,
                      indOnCallHolidayEnd: IndiaHolidayOnCallEnd,
                      backlogWorkStart: backlog.WorkingHoursStart,
                      baclogWorkEnd: backlog.WorkingHoursEnd,
                    }}
                    readOnly={false}
                    previewOnly={!this.canPreview()}
                    onClassChange={this.onClassChange}
                    onChunkChange={this.onChunkChange}
                    userId={this.state.user.ID}
                    onEngineerChange={this.onEngineerChange.bind(this)}
                    rotationUser={user}
                    backlogUsers={peopleInBacklog}
                    rotations={this.state.selectedRotations}
                    removedRotations={this.state.removedRotations}
                    rotationBacklog={backlog}
                    rotationClass={rotationClass}
                    rotationChunk={rotationChunk}
                    onSelectedRotationsUpdate={this.onSelectedRotationsUpdate.bind(
                      this
                    )}
                    onRemovedRotationsUpdate={this.onRemovedRotationsUpdate}
                    timeZone={timeZone}
                    teams={teams}
                    ActiveUser={ActiveUser}
                  />
                </div>
              </div>
            )}

            {(Object.keys(this.state.selectedRotations).length !== 0 ||
              Object.keys(this.state.removedRotations).length !== 0) && (
              <React.Fragment>
                <div className="row pt-1 pb-3 justify-content-md-center">
                  {Object.keys(this.state.selectedRotations).length !== 0 && (
                    <RotationList
                      rotations={this.state.selectedRotations}
                      onSelectedRotationsUpdate={this.onSelectedRotationsUpdate}
                      timeZone={this.state.timeZone}
                      type="New Rotations"
                    />
                  )}
                  {Object.keys(this.state.removedRotations).length !== 0 && (
                    <RotationList
                      rotations={this.state.removedRotations}
                      timeZone={this.state.timeZone}
                      type="Removed Rotations"
                    />
                  )}
                </div>
                <div className="row pb-5">
                  <div className="col-sm-12 mx-auto">
                    <button
                      className="btn btn-primary"
                      onClick={this.onSubmitRotation}
                    >
                      Submit Rotations
                    </button>
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
        {this.state.showLoader && (
          <Loader showLoader={this.state.showLoader} />
        )}
        {!this.state.showLoader && this.state.modalBody && (
          <MyModal
            visible={true}
            modalBackdropClicked={this.handleToggle}
            header={"Response"}
            body={this.state.modalBody}
            btnPrimary={{
              text: "OK",
              color: "btn-primary",
              onClick: () => {
                this.props.history.push(`/backlog/${this.state.backlog.ID}`);
              },
            }}
          />
        )}
      </div>
    );
  }
}

export default Rotation;
